<template>
  <div>
    <img class="home_img" src="../assets/home/1.png" />
    <img class="home_img" src="../assets/home/2.png" />
    <img class="home_img" src="../assets/home/3.png" />
    <img class="home_img" src="../assets/home/4.png" />
    <div style="text-align: center; ">
      <a style="display: flex;justify-content: center; align-items: center;" target="_black"
        href="http://beian.miit.gov.cn">
        <img class="gov_img" src="../assets/gov.png" />
        <span style="margin-left: 5px">{{ recordText }}</span>
      </a>
    </div>


  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      href: ''
    }
  },
  computed: {
    recordText() {
      if (this.href.indexOf('vip') > -1) {
        return '浙ICP备17020023号-3'
      } else {
        return '浙ICP备17020023号-2'
      }
    }
  },
  created() {
    this.href = window.location.host
  }
}
</script>

<style scoped>
.home_img {
  width: 100%;
}

.gov_img {
  width: 20px;
  height: 20px;
}
</style>
